import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import screenshot from "../images/blaiser-screeenshot.png";
import "../styles/_macbook-pro.scss";
import welltecLogo from "../images/welltec-logo.svg";
import iniosLogo from "../images/ineos-logo.svg";
import morten from "../images/morten.png";
import johan from "../images/johan.jpeg";
import mette from "../images/mette.jpeg";
import linkedInIcon from "../images/linkedin.svg";
import cloudSvg from "../images/cloud-computing.svg";
import projectSvg from "../images/project-structure.svg";
import brainSvg from "../images/brain-process.svg";
import reportingSvg from "../images/notepad.svg";
import qaSvg from "../images/note.svg";

import styles from "./index.module.scss";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="bg-primary-dark text-white p-10">
        <div className="flex flex-wrap max-w-7xl mx-auto">
          <div className="my-2 w-full overflow-hidden md:w-1/2">
            <h1 className="mb-6 text-6xl">
              Maximize project value with
              <span className="text-6xl">
                <span className={styles.beta} />
                <span className="italic">laiser</span>
              </span>
            </h1>
            <h3 className="text-lg mb-20 italic">
              A fast, easy-to-use and versatile online project simulation tool
            </h3>
            <ul className="ml-5 list-disc">
              <li>Effectuate data-driven decision making</li>
              <li>
                Analyze outcomes to exploit opportunities and mitigate risks
              </li>
              <li>Integrate uncertainty to create realistic expectations</li>
              <li>Optimize project value by successive refinements</li>
            </ul>
            <a href="https://app.blaiser.io/register" target="blank">
              <button className="bg-green-400 py-5 px-10 my-10 rounded-xl font-bold">
                Get Started For Free
              </button>
            </a>
          </div>

          <div className="my-2 px-2 w-full md:w-1/2">
            <div
              id="macbook-pro"
              className={`${styles.mac} container grid-xl text-center`}
            >
              {" "}
              <div className="columns">
                <div className="column col-12">
                  <div className="device device-macbook-pro device-spacegray">
                    <div className="device-frame">
                      <img className="device-content" src={screenshot} />
                    </div>
                    <div className="device-stripe"></div>
                    <div className="device-header"></div>
                    <div className="device-sensors"></div>
                    <div className="device-btns"></div>
                    <div className="device-power"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-300 px-10 py-14">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-center text-4xl">
            Working with world class partners
          </h2>
          <div className="flex flex-wrap overflow-hidden mt-10">
            <div className="w-full overflow-hidden md:w-1/2 mx-auto">
              <a href="https://www.welltec.com/" target="blank">
                <img src={welltecLogo} className="max-w-xs mx-auto p-10" />
              </a>
            </div>
            <div className="w-full overflow-hidden md:w-1/2">
              <a href="https://www.ineos.com/" target="blank">
                <img src={iniosLogo} className="max-w-xs mx-auto p-10" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-100 p-10">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 w-full overflow-hidden md:w-1/2">
              <div
                className="flex"
                data-sal="slide-right"
                data-sal-delay="300"
                data-sal-easing="easeOutExpo"
              >
                <img
                  className={`${styles.grayscale} w-24 h-24 mx-auto float-left rounded-full mr-2`}
                  src={mette}
                />
                <div>
                  <p className="font-bold">Mette Lind F&#252;rstnow</p>
                  <p className="text-sm">
                    Reservoir Engineering, Business Development Manager at
                    Welltec
                  </p>
                  <p className="border-l-4 border-gray-300 px-2 mt-4 italic">
                    We use Blaiser to demonstrate the benefits we can provide to
                    our customers, based on their specific project requirements
                    and needs. Before we started using Blaiser, we were not able
                    to produce a coherent and quantitative analysis of the risk
                    reduction potential we were offering with our advanced tools
                    and technologies. The new project specific simulation
                    approach has been received very well and has led to
                    increased business value - both for us and our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="my-2 px-2 w-full overflow-hidden md:w-1/2">
              <div
                className="flex"
                data-sal="slide-left"
                data-sal-delay="600"
                data-sal-easing="ease"
              >
                <img
                  className={`${styles.grayscale} w-24 h-24 mx-auto float-left rounded-full mr-2`}
                  src={johan}
                />
                <div>
                  <p className="font-bold">Johan Svendsen</p>
                  <p className="text-sm">
                    Business Development Manager at INEOS
                  </p>
                  <p className="border-l-4 border-gray-300 px-2 mt-4 italic">
                    We ported a complicated excel-based single point calculation
                    model for a huge joint venture project to Blaiser. This gave
                    us a lot of benefits by creating a much better overview and
                    understanding of the value creation, complex interrelations
                    and uncertainties across the entire value chain. We firmly
                    believe that the new simulation model will be extremely
                    valuable for all major decisions in this project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white p-10">
        <h2 className="text-center text-4xl pb-10">Product Features</h2>

        <div className="flex flex-wrap -mx-3 overflow-hidden">
          {/*
            Cloud
          */}
          <div
            className="my-3 px-3 w-full overflow-hidden md:w-1/2"
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <div className="max-w-xs mx-auto md:mr-0 text-center">
              <h2 className="text-2xl mb-4 underline">
                Accessible In The Cloud
              </h2>
              <p>No installation required</p>
              <p>Up and running in miutes</p>
              <p>
                <small>(No credit card required)</small>
              </p>
            </div>
          </div>

          <div
            className="my-3 mb-20 px-3 w-full overflow-hidden md:w-1/2 md:pr-20"
            data-sal="slide-left"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <img
              src={cloudSvg}
              className={`${styles.featureIcon} max-w-xs mx-auto md:ml-0`}
            />
          </div>

          {/*
            Making the complex simple
          */}
          <div
            className="my-3 mb-20 px-3 w-full overflow-hidden md:w-1/2 md:pr-20"
            data-sal="slide-right"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <img
              src={projectSvg}
              className={`${styles.featureIcon} max-w-xs mx-auto md:mr-0`}
            />
          </div>
          <div
            className="my-3 px-3 w-full overflow-hidden md:w-1/2"
            data-sal="slide-left"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <div className="max-w-xs mx-auto md:ml-0 text-center">
              <h2 className="text-2xl mb-4 underline">
                Making The Complex Simple
              </h2>
              <p>Easy and intuitive application interface</p>
              <p>Simple, yet comprehensive modelling capabilities</p>
              <p>
                Project hierarchy workflow for modelling of complex projects
              </p>
            </div>
          </div>

          {/*
            Fast Simulation Engine
          */}
          <div
            className="my-3 px-3 w-full overflow-hidden md:w-1/2"
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <div className="max-w-xs mx-auto md:mr-0 text-center">
              <h2 className="text-2xl mb-4 underline">
                Fast Simulation Engine
              </h2>
              <p>Extremely fast Monte Carlo simulation - even for big models</p>
            </div>
          </div>

          <div
            className="my-3 mb-20 px-3 w-full overflow-hidden md:w-1/2 md:pr-20"
            data-sal="slide-left"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <img
              src={brainSvg}
              className={`${styles.featureIcon} max-w-xs mx-auto md:ml-0`}
            />
          </div>

          {/*
            Reporting & Collaborative
          */}
          <div
            className="my-3 mb-20 px-3 w-full overflow-hidden md:w-1/2 md:pr-20"
            data-sal="slide-right"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <img
              src={reportingSvg}
              className={`${styles.featureIcon} max-w-xs mx-auto md:mr-0`}
            />
          </div>
          <div
            className="my-3 px-3 w-full overflow-hidden md:w-1/2"
            data-sal="slide-left"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <div className="max-w-xs mx-auto md:ml-0 text-center">
              <h2 className="text-2xl mb-4 underline">
                Interactive Reporting & Collaboration
              </h2>
              <p>
                Reporting with dynamic data interaction and seamless sharing
                options
              </p>
            </div>
          </div>

          {/*
            Quality Assurance
          */}
          <div
            className="my-3 px-3 w-full overflow-hidden md:w-1/2"
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <div className="max-w-xs mx-auto md:mr-0 text-center">
              <h2 className="text-2xl mb-4 underline">Quality Assurance</h2>
              <p>Structure and transparency for quality assurance</p>
            </div>
          </div>

          <div
            className="my-3 mb-20 px-3 w-full overflow-hidden md:w-1/2 md:pr-20"
            data-sal="slide-left"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <img
              src={qaSvg}
              className={`${styles.featureIcon} max-w-xs mx-auto md:ml-0`}
            />
          </div>
        </div>
      </section>

      <section className="bg-blue-900 text-gray-100 p-10">
        <div className="flex flex-col max-w-4xl mx-auto text-center">
          <h2 className="text-4xl mb-4">
            Let&apos;s help model your next project
          </h2>
          <h4 className="mb-10 italic underline">
            Book a FREE model consultation
          </h4>
          <p className="text-lg font-light leading-relaxed mt-0 mb-4 ">
            We offer to discuss and build a simulation model for your next
            project to get you started with project simulation. During this
            session, we will frame your project together and build a high-level
            model to give you an idea of what you can achieve by simulating
            various outcomes of your project. The key take-away will be an
            understanding of how simulation can help you increase the project
            value and, at the end of the day, make your project successful.
          </p>
          <button className="bg-green-400 max-w-sm mx-auto py-5 px-10 my-10 rounded-xl font-bold">
            Book a free model consultation
          </button>
          <a target="blank" href="https://www.linkedin.com/in/mortenaagesen/">
            <img
              className="w-16 h-16 mx-auto rounded-full border-gray-100"
              src={morten}
            />
          </a>
          <div className="my-4 text-sm">
            You will be working directly with our CEO,
            <br />
            <a
              className="underline"
              target="blank"
              href="https://www.linkedin.com/in/mortenaagesen/"
            >
              Morten Aagesen{" "}
              <img className="px-1 w-6 h-6 inline" src={linkedInIcon} />
            </a>
          </div>
        </div>
      </section>
      <section className="bg-white p-10 text-center md:p-8">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-center text-4xl pb-10">Team</h2>
          <p className="mb-8">
            As a team, we are passionate about improving the decision basis for
            decision-making in complex projects. We provide tools and services
            to help our customers improve their performance and meet their goals
            by integrating data-driven decision-making in a volatile and digital
            world
          </p>
          <p className="italic">
            It&apos;s not about simulation - it&apos;s about project outcomes
          </p>
          <p className="italic">
            It&apos;s not about risk management - it&apos;s about business
            performance
          </p>
          <p className="italic">
            It&apos;s not about messy feature packed spreadsheets - it&apos;s
            about coherent structure and transparency with fast and easy-to-use
            functionality
          </p>
          <p className="italic">
            It&apos;s not about hitting the bull&apos;s eye - it&apos;s about
            making sure you hit the board
          </p>
          <p className="italic">
            It&apos;s not about a one-off exercise - it&apos;s about continuous
            improvement
          </p>
          <p className="italic">
            It&apos;s not about single point estimates - it&apos;s about ranges
            and probabilities
          </p>
        </div>
      </section>
    </Layout>
  );
}

//<p>
//We are a team of people with origins from the oil and gas industry
//with more than 30 years of combined knowlege of risk modelling and
//bla bla
//</p>
export default IndexPage;
